import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';

import { HomeComponent } from './containers/home/home.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { CarouselComponent } from './components/carousel/carousel.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        QuizComponent,
        DialogComponent,
        CarouselComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule
    ],
    providers: [CookieService],
    bootstrap: [AppComponent]
})
export class AppModule { }
