import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition, group, query, animateChild } from '@angular/animations';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styles: [],
    animations: [
        trigger('overlay', [
            state('void', style({
                backgroundColor: 'rgba(0,0,0, 0)'
            })),
            state('*', style({
                backgroundColor: 'rgba(0,0,0, 0.8)'
            })),      
            transition('* <=> *', [
                group([
                    query('@dialog', animateChild()),
                    animate('0.9s cubic-bezier(0.55, 0.31, 0.15, 0.93)')
                ])
            ])
        ]),
        trigger('dialog', [
            state('void', style({
                transform: 'translateY(100vh)'
            })),
            state('*', style({
                transform: 'translateY(0vh)'
            })),      
            transition('* <=> *', [
                animate('0.9s cubic-bezier(0.55, 0.31, 0.15, 0.93)')
            ])
        ])
    ]
})
export class DialogComponent {

    @Input() visible: boolean;
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }
    
    close() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }

}
