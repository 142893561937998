import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styles: []
})
export class HomeComponent {

    private COOKIE_CONSENT = "cookieconsent";
    private COOKIE_CLASS = "show-cookie-banner";

    offers = [
        {
            caption: "Fizetések magasan a piac felett",
            src: "assets/images/icon-analytics.svg"
        },{
            caption: "Készpénzbónusz",
            src: "assets/images/icon-payment.svg"
        },{
            caption: "Részvénybónusz",
            src: "assets/images/icon-document.svg"
        },{
            caption: "Szakmai képzések",
            src: "assets/images/icon-presentation.svg"
        },{
            caption: "Magán egészségbiztosítás",
            src: "assets/images/icon-health.svg"
        },{
            caption: "Éttermi étkezés",
            src: "assets/images/icon-meal.svg"
        },{
            caption: "Edzőtermi bérlet",
            src: "assets/images/icon-training.svg"
        },{
            caption: "Kutyabarát munkahely",
            src: "assets/images/icon-dog-friendly.svg"
        }
    ];
    
    properties = [
        {
            caption: "Segítőkészek",
            src: "assets/images/icon-helpful.svg"
        },{
            caption: "Hozzád hasonlóan éles eszűek",
            src: "assets/images/icon-mind.svg"
        },{
            caption: "Clean code szemléletűek",
            src: "assets/images/icon-code.svg"
        },{
            caption: "Eredményeidet értékeljük",
            src: "assets/images/icon-success.svg"
        },{
            caption: "Szakmailag kompetens vezetők",
            src: "assets/images/icon-business-man.svg"
        },{
            caption: "Stabil és tervezhető jövőt kínálunk",
            src: "assets/images/icon-growth.svg"
        }
    ];

    carouselItems = [
        "assets/images/iroda-1.jpg",
        "assets/images/iroda-2.jpg",
        "assets/images/iroda-3.jpg",
        "assets/images/iroda-4.jpg",
        "assets/images/iroda-5.jpg",
        "assets/images/iroda-6.jpg",
        "assets/images/iroda-7.jpg"
    ];

    showVideo = {
        develop: false,
        choose: false
    };

    constructor(private cookieService: CookieService) { } 

    ngOnInit() {
        const cookieExists: boolean = this.cookieService.check(this.COOKIE_CONSENT);
        if (!cookieExists) {
            document.body.classList.add(this.COOKIE_CLASS);
            setTimeout(() => {
                const height = document.querySelector('.section--cookie-banner').clientHeight;
                if (height) {
                    document.body.style.paddingBottom = `${height}px`;
                }
            }, 100);
        }
    }

    acceptCookies(event: Event) {
        event.preventDefault();
        this.cookieService.set(this.COOKIE_CONSENT, "1", 1000, "/");
        document.body.classList.remove(this.COOKIE_CLASS);
        document.body.style.paddingBottom = '0';
    }
}
