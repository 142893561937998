import { Component } from '@angular/core';

@Component({
    selector: 'app-quiz',
    templateUrl: './quiz.component.html',
    styles: []
})
export class QuizComponent {

    questions = [
        {
            text: "Egy ütő meg egy labda összesen 1 dollár 10 centbe kerül. Az ütő 1 dollárral többe kerül, mint a labda. Mennyibe kerül a labda?",
            answer: 5,
            unit: "cent",
            value: null
        },{
            text: "Ha 5 gép 5 perc alatt 5 terméket gyárt, akkor mennyi idő alatt készít 100 gép 100 terméket?",
            answer: 5,
            unit: "perc",
            value: null
        },{
            text: "Egy tavon egy párnában nőnek a tavirózsák. A párna területe naponta megduplázódik. 48 napba telik, amíg a tavirózsák teljesen beborítják a tavat. Mennyi ideig tart, amíg a tavirózsák párnája csak a fél tavat lepi el?",
            answer: 47,
            unit: "nap",
            value: null
        }
    ];

    availableQuestions = [];
    showResult: boolean = false;

    constructor() { }

    next() {
        if( this.questions.length ) {
            this.availableQuestions.push(this.questions.shift());
        }
    }

    evaluation() {
        this.showResult = true;
    }
    
    get numberOfCorrectAnswers() {
        return this.availableQuestions.filter(question => question.answer === Number(question.value)).length;
    }
}
