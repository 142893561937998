import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styles: [],
    animations: [
        trigger('thumbState', [
            state('inactive', style({
                opacity: 0, transform: 'scale(0.5)'
            })),
            state('active', style({ 
                opacity: 1, transform: 'scale(1)'
            })),
            transition('inactive => active', animate('500ms cubic-bezier(0.785, 0.135, 0.15, 0.86)')),
            transition('active => inactive', animate('500ms cubic-bezier(0.785, 0.135, 0.15, 0.86)'))
        ])
    ]
})
export class CarouselComponent {

    @Input() images: Array<string> = [];
    @Output() change: EventEmitter<number> = new EventEmitter<number>();
    pointer = 0;

    constructor() { }

    onClickThumb(event) {
        event.layerX < (event.target.width / 2) ? this.previous() : this.next();
    }
    
    next() {
        this.pointer = ( this.pointer < this.images.length - 1 ) ? this.pointer + 1 : 0 ;
    }
    
    previous() {
        this.pointer = ( this.pointer > 0 ) ? this.pointer - 1 : this.images.length - 1 ;
    }


}
